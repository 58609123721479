@charset "UTF-8";
.bild {
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  background-color: white;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  text-decoration-skip-ink: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "MessinaSans", sans-serif;
  font-size: 15px;
  line-height: 18px;
}

#seite:after {
  background-color: white;
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -100;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  position: relative;
}
li::before {
  content: "–";
  left: 0;
  position: absolute;
}

sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

svg {
  padding: 1px 1px 1px 1px;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

*:focus-visible {
  outline: 2px solid black;
}

img {
  color: transparent;
  display: block;
  line-height: 1em;
  text-indent: -9999px;
}

@font-face {
  font-family: "MessinaSans";
  src: url("/fonts/Messina-Sans-Bold/MessinaSansWeb-Bold.woff2") format("woff2"), url("/fonts/Messina-Sans-Bold/MessinaSansWeb-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
.cursor {
  display: none;
  font-family: ROM, sans-serif;
  font-weight: 400;
  left: 0;
  position: fixed;
  pointer-events: none;
  top: 0;
}
.cursor--symbol {
  z-index: 5001;
}
.cursor__inner {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  transition: width 0.5s, height 0.5s, transform 0.5s;
}
.cursor__inner--symbol {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
body[data-cursor-touch=cursor] .cursor {
  display: block;
}
body[data-cursor-touch=touch] .cursor {
  display: none;
}
body .cursor--symbol {
  mix-blend-mode: difference;
}
body .cursor__inner {
  width: 15px;
  height: 15px;
  background-color: #fff;
}
body[data-body-cursor=kreuz][data-cursor-touch=cursor] .cursor__inner {
  width: 15px;
  height: 15px;
  position: relative;
  transform: translate(0%, 0%);
  background-color: transparent;
}
body[data-body-cursor=kreuz][data-cursor-touch=cursor] .cursor__inner--symbol {
  mix-blend-mode: difference;
}
body[data-body-cursor=kreuz][data-cursor-touch=cursor] .cursor__inner--symbol::before, body[data-body-cursor=kreuz][data-cursor-touch=cursor] .cursor__inner--symbol::after {
  content: "";
  position: absolute;
  background-color: #fff;
}
body[data-body-cursor=kreuz][data-cursor-touch=cursor] .cursor__inner--symbol::before {
  width: 1px;
  height: 15px;
  top: 0;
  left: 50%;
}
body[data-body-cursor=kreuz][data-cursor-touch=cursor] .cursor__inner--symbol::after {
  width: 15px;
  height: 1px;
  top: 50%;
  left: 0;
}

.error {
  font-family: "MessinaSans";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.body--haltung {
  grid-auto-rows: max-content;
  background-color: #0B0B0B;
  color: #fff;
}
.body--haltung .main {
  padding-top: 20px;
  min-height: 100vh;
}

.haltung {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  grid-auto-rows: max-content;
}
.haltung__texte {
  grid-column: 2/7;
  width: 100%;
}
.haltung__text {
  background-color: #0B0B0B;
  padding-top: 200px;
}
.haltung__text:first-child {
  padding-top: 0;
}

@media (max-width: 767px) {
  .body--haltung .main {
    padding-top: 240px;
  }
  .haltung__texte {
    grid-column: 1/4;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .haltung__texte {
    grid-column: 2/6;
    width: 100%;
  }
}
.header {
  display: grid;
  column-gap: 16px;
  position: fixed;
  left: 0;
  top: 20px;
  padding: 0 20px;
  width: 100%;
  z-index: 100;
}
.header__element-link {
  background: linear-gradient(0deg, white 0%, white 50%, #000 50%, #000 100%);
  text-decoration: none;
  background-clip: text;
  background-size: 100% 200%;
  background-position-y: 0;
  color: transparent;
}
.header__element--logo {
  font-size: 15px;
  line-height: 18px;
}
.header__element--projekt {
  display: none;
  grid-column-start: 3;
  grid-column-end: -2;
  margin: 0 -5px 0 -5px;
}
.body--projekte-entry .header__element--projekt {
  display: block;
}
.header__menuepunkt {
  cursor: pointer;
  margin: 0 5px 0 5px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  filter: blur(2px);
  color: transparent;
  background: linear-gradient(0deg, white 0%, white 50%, #000 50%, #000 100%);
  text-decoration: none;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 100% 200%;
  background-position-y: 0;
}
.header__menuepunkt[data-menue-projekt=aktiv], .header__menuepunkt:hover {
  filter: blur(0px);
}
.header__beschriftung {
  position: sticky;
  margin-top: 33px;
}
.header__beschreibung {
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 0 -5px;
  width: 100%;
}
.header__text {
  margin: 0 5px 0 5px;
  text-decoration: none;
  color: #000;
}
.header__titel {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  padding-bottom: 12px;
  width: 50%;
  text-decoration: none;
  color: #000;
  width: 100%;
}

.body--haltung .header__element--logo, .body--kontakt .header__element--logo {
  color: #fff;
}

@media (max-width: 767px) {
  .header {
    padding-top: 0px;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 11px;
  }
  .header__element--projekt {
    padding-top: 10px;
  }
  .header__element--menue {
    grid-column-start: -1;
    grid-column-end: -2;
  }
  .header__element--projekt {
    grid-column-start: 1;
    grid-column-end: -2;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .header {
    padding-top: 0px;
    grid-template-columns: repeat(6, 1fr);
  }
  .header__element--menue {
    grid-column-start: -1;
    grid-column-end: -2;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .header {
    padding-top: 0px;
    grid-template-columns: repeat(9, 1fr);
  }
  .header__element--menue {
    grid-column-start: -1;
    grid-column-end: -2;
  }
}
@media (min-width: 1440px) and (max-width: 2561px) {
  .header {
    padding-top: 0px;
    grid-template-columns: repeat(12, 1fr);
  }
  .header__element--menue {
    grid-column-start: -1;
    grid-column-end: -2;
  }
}
@media (min-width: 2562px) {
  .header {
    padding-top: 0px;
    grid-template-columns: repeat(18, 1fr);
  }
  .header__element--menue {
    grid-column-start: -1;
    grid-column-end: -2;
  }
}
.body--kontakt {
  background-color: #0B0B0B;
  color: #fff;
}
.body--kontakt .main {
  padding-top: 20px;
  min-height: 100vh;
}

.kontakt {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  display: grid;
  column-gap: 16px;
  padding: 0 20px;
  width: 100%;
}
.kontakt__kontakt p {
  margin-bottom: 100px;
}
.kontakt__kontakt p:last-child {
  margin-bottom: 0px;
}
.kontakt__kontakt a {
  color: #fff;
  text-decoration: none;
}
.kontakt__kontakt a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .body--kontakt .main {
    padding-top: 240px;
  }
  .kontakt {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: max-content;
    column-gap: 11px;
  }
  .kontakt__kontakt {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 100px;
  }
  .kontakt__bild {
    grid-column-start: 1;
    grid-column-end: 5;
    margin: 0 -22px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .kontakt {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: max-content;
  }
  .kontakt__kontakt {
    grid-column-start: 2;
    grid-column-end: 6;
    margin-bottom: 100px;
  }
  .kontakt__bild {
    grid-column: 2/6;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .kontakt {
    grid-template-columns: repeat(9, 1fr);
    grid-auto-rows: max-content;
  }
  .kontakt__kontakt {
    grid-column-start: 2;
    grid-column-end: 5;
  }
  .kontakt__bild {
    grid-column: 5/9;
  }
  .kontakt__bild .macro-bild__img, .kontakt__bild .macro-bild__picture {
    position: relative;
  }
  .kontakt__bild .kontakt__bild-bild {
    padding-bottom: 0 !important;
  }
  .kontakt__bild-img {
    object-fit: cover;
    object-position: center center;
  }
}
@media (min-width: 1440px) and (max-width: 2561px) {
  .kontakt {
    grid-template-columns: repeat(24, 1fr);
    grid-auto-rows: max-content;
  }
  .kontakt__kontakt {
    grid-column-start: 2;
    grid-column-end: 5;
  }
  .kontakt__bild {
    grid-column: 5/11;
  }
  .kontakt__bild .macro-bild__img, .kontakt__bild .macro-bild__picture {
    position: relative;
  }
  .kontakt__bild .kontakt__bild-bild {
    padding-bottom: 0 !important;
  }
  .kontakt__bild-img {
    object-fit: cover;
    object-position: center center;
  }
}
@media (min-width: 2562px) {
  .kontakt {
    grid-template-columns: repeat(36, 1fr);
    grid-auto-rows: max-content;
  }
  .kontakt__kontakt {
    grid-column-start: 2;
    grid-column-end: 5;
  }
  .kontakt__bild {
    grid-column: 5/17;
  }
  .kontakt__bild .macro-bild__img, .kontakt__bild .macro-bild__picture {
    position: relative;
  }
  .kontakt__bild .kontakt__bild-bild {
    padding-bottom: 0 !important;
  }
  .kontakt__bild-img {
    object-fit: cover;
    object-position: center center;
  }
}
.menue {
  font-family: "MessinaSans", sans-serif;
  font-size: 15px;
  line-height: 18px;
  height: 100%;
  width: 100%;
  position: relative;
  font-weight: 700;
}
.menue__links {
  position: fixed;
  text-decoration: none;
  top: 20px;
}
.menue__link {
  cursor: pointer;
  display: inherit;
  text-decoration: none;
  filter: blur(2px);
  margin-top: 12px;
  background: linear-gradient(0deg, white 0%, white 50%, #0B0B0B 50%, #0B0B0B 100%);
  color: transparent;
  text-decoration: none;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 100% 200%;
  background-position-y: 0;
}
.menue__link:first-child {
  margin-top: 0;
}
.body--haltung .menue__link, .body--kontakt .menue__link {
  background: linear-gradient(0deg, #0B0B0B 0%, #0B0B0B 50%, white 50%, white 100%);
  color: transparent;
  text-decoration: none;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 100% 200%;
  background-position-y: 0;
}
.menue__link--aktiv {
  filter: blur(0);
}
.menue__link:hover {
  filter: blur(0);
}

.projekt__projekte {
  display: grid;
  row-gap: 0;
  column-gap: 16px;
  padding: 0 20px;
}
.projekt__projekt--konzept {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% + 40px);
  overflow-x: hidden;
  scroll-snap-align: start;
}
.projekt__menue {
  position: sticky;
  left: 0;
  z-index: 100000;
}
.projekt__beschriftung {
  z-index: 100;
  margin: 0 20px;
  color: transparent;
  background: linear-gradient(0deg, white 0%, white 50%, #000 50%, #000 100%);
  text-decoration: none;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 100% 200%;
  background-position-y: 0;
}
.projekt__beschriftung a {
  z-index: 100;
  color: transparent;
  text-decoration: none;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 100% 200%;
  background-position-y: 0;
}
.projekt__beschreibung {
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 3px -5px;
}
.projekt__text {
  margin: 0 5px 0 5px;
  text-decoration: none;
}
.projekt__titel {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
}
.projekt__projektinfo {
  font-size: 10px;
  margin-top: 4px;
  line-height: 12px;
  font-weight: 700;
  text-decoration: none;
}
.projekt__bau-bild {
  margin: 0;
}
.projekt__bau-video-container, .projekt__entstehung-video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
}
.projekt__bau-video, .projekt__entstehung-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.projekt__konzept-wrap {
  display: flex;
  padding: 80px 0px 80px 0px;
  height: 80vh;
  background-color: #F2F2F2;
  width: 100%;
}
.projekt__konzept-video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: calc(80vh - 160px);
  margin: 0px 30px 0px 30px;
  mix-blend-mode: darken;
}
.projekt__konzept-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.projekt__konzept-bild {
  margin: 0px 30px 0px 30px;
  height: calc(80vh - 160px);
  mix-blend-mode: darken;
}
.projekt__konzept-bild .macro-bild__picture {
  height: 100%;
  position: relative;
  width: auto;
}
.projekt__konzept-bild-bild.macro-bild {
  height: 100%;
  width: auto;
}
.projekt__konzept-bild-img.macro-bild__img {
  position: relative;
  height: 100%;
  width: auto;
}
.projekt__bildquelle {
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  color: rgb(182, 182, 182);
  margin: 8px 0 0 0;
}
.projekt__projekt {
  column-gap: 16px;
}
.projekt__projekt--konzept {
  margin: 0px -20px 0 -20px;
}
.projekt__projekt-container {
  display: inline-grid;
  column-gap: 16px;
  padding: 0px 20px 0 20px;
}
.projekt__projekt-container--entstehung {
  margin: 0px -20px 0 -20px;
  background-color: black;
}

@media (max-width: 767px) {
  .projekt__projekte {
    grid-template-columns: repeat(4, 1fr);
  }
  .projekt__beschriftung {
    margin-top: 147px;
  }
  .projekt__projekt {
    grid-column-start: 1;
    grid-column-end: 5;
    column-gap: 11px;
    display: grid;
  }
  .projekt__projekt--bau, .projekt__projekt--entstehung {
    padding-top: 62px;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-template-columns: repeat(28, 1fr);
  }
  .projekt__projekt-container {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 11px;
  }
  .projekt__projekt-container--entstehung {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__menue {
    top: 18px;
  }
  .projekt__bau-video-container, .projekt__entstehung-video-container {
    margin-bottom: 158px;
  }
  .projekt__bau-bild, .projekt__entstehung-bild {
    padding-bottom: 158px;
  }
  .projekt__bau-bild--hauptbild-bildXlQuer, .projekt__entstehung-bild--hauptbild-bildXlQuer {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__bau-bild--hauptbild-bildXlHoch, .projekt__entstehung-bild--hauptbild-bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 22;
  }
  .projekt__bau-bild--bildXlHoch, .projekt__entstehung-bild--bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 22;
  }
  .projekt__bau-bild--bildXlHoch-0, .projekt__entstehung-bild--bildXlHoch-0 {
    grid-column-start: 19;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildQuer, .projekt__entstehung-bild--bildQuer {
    grid-column-start: 1;
    grid-column-end: 19;
  }
  .projekt__bau-bild--bildQuer-0, .projekt__entstehung-bild--bildQuer-0 {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildM-1, .projekt__entstehung-bild--bildM-1 {
    grid-column-start: 1;
    grid-column-end: 12;
  }
  .projekt__bau-bild--bildM-2, .projekt__entstehung-bild--bildM-2 {
    grid-column-start: 18;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-LS, .projekt__entstehung-bild--bildL-LS {
    grid-column-start: 1;
    grid-column-end: 19;
  }
  .projekt__bau-bild--bildS-LS, .projekt__entstehung-bild--bildS-LS {
    grid-column-start: 22;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-SL, .projekt__entstehung-bild--bildL-SL {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildS-SL, .projekt__entstehung-bild--bildS-SL {
    grid-column-start: 1;
    grid-column-end: 8;
  }
  .projekt__konzept-wrap {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .projekt__projekte {
    grid-template-columns: repeat(6, 1fr);
  }
  .projekt__beschriftung {
    padding-top: 62px;
  }
  .projekt__projekt {
    grid-column-start: 1;
    grid-column-end: -1;
    display: grid;
  }
  .projekt__projekt--bau, .projekt__projekt--entstehung {
    padding-top: 46px;
    grid-column-start: 1;
    grid-column-end: -2;
    grid-template-columns: repeat(35, 1fr);
  }
  .projekt__projekt-container {
    grid-template-columns: repeat(6, 1fr);
  }
  .projekt__projekt-container--entstehung {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__menue {
    top: 18px;
  }
  .projekt__bau-video-container, .projekt__entstehung-video-container {
    margin-bottom: 158px;
  }
  .projekt__bau-bild, .projekt__entstehung-bild {
    padding-bottom: 158px;
  }
  .projekt__bau-bild--hauptbild-bildXlQuer, .projekt__entstehung-bild--hauptbild-bildXlQuer {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__bau-bild--hauptbild-bildXlHoch, .projekt__entstehung-bild--hauptbild-bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 26;
  }
  .projekt__bau-bild--bildXlHoch, .projekt__entstehung-bild--bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 26;
  }
  .projekt__bau-bild--bildXlHoch-0, .projekt__entstehung-bild--bildXlHoch-0 {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildQuer, .projekt__entstehung-bild--bildQuer {
    grid-column-start: 1;
    grid-column-end: 26;
  }
  .projekt__bau-bild--bildQuer-0, .projekt__entstehung-bild--bildQuer-0 {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildM-1, .projekt__entstehung-bild--bildM-1 {
    grid-column-start: 1;
    grid-column-end: 15;
  }
  .projekt__bau-bild--bildM-2, .projekt__entstehung-bild--bildM-2 {
    grid-column-start: 22;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-LS, .projekt__entstehung-bild--bildL-LS {
    grid-column-start: 1;
    grid-column-end: 26;
  }
  .projekt__bau-bild--bildS-LS, .projekt__entstehung-bild--bildS-LS {
    grid-column-start: 29;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-SL, .projekt__entstehung-bild--bildL-SL {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildS-SL, .projekt__entstehung-bild--bildS-SL {
    grid-column-start: 1;
    grid-column-end: 8;
  }
  .projekt__konzept-wrap {
    margin-left: -10px;
    padding-right: calc(16.146vw - 10px);
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .projekt__projekte {
    grid-template-columns: repeat(9, 1fr);
  }
  .projekt__beschriftung {
    position: sticky;
    top: 62px;
    left: 16px;
    width: calc((100% - 0px - 160px) / 9 * 2 + 16px + 0px);
  }
  .projekt__projekt {
    grid-column-start: 1;
    grid-column-end: -1;
    display: grid;
  }
  .projekt__projekt--bau, .projekt__projekt--entstehung {
    padding-top: 62px;
    grid-column-start: 3;
    grid-column-end: -2;
    grid-template-columns: repeat(30, 1fr);
  }
  .projekt__projekt--konzept {
    grid-template-columns: 1fr;
  }
  .projekt__projekt-container {
    grid-template-columns: repeat(9, 1fr);
  }
  .projekt__projekt-container--entstehung {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__menue {
    top: 18px;
  }
  .projekt__bau-video-container, .projekt__entstehung-video-container {
    margin-bottom: 158px;
  }
  .projekt__bau-bild, .projekt__entstehung-bild {
    padding-bottom: 158px;
  }
  .projekt__bau-bild--hauptbild-bildXlQuer, .projekt__entstehung-bild--hauptbild-bildXlQuer {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__bau-bild--hauptbild-bildXlHoch, .projekt__entstehung-bild--hauptbild-bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 21;
  }
  .projekt__bau-bild--bildXlHoch, .projekt__entstehung-bild--bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 21;
  }
  .projekt__bau-bild--bildXlHoch-0, .projekt__entstehung-bild--bildXlHoch-0 {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildQuer, .projekt__entstehung-bild--bildQuer {
    grid-column-start: 1;
    grid-column-end: 21;
  }
  .projekt__bau-bild--bildQuer-0, .projekt__entstehung-bild--bildQuer-0 {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildM-1, .projekt__entstehung-bild--bildM-1 {
    grid-column-start: 1;
    grid-column-end: 14;
  }
  .projekt__bau-bild--bildM-2, .projekt__entstehung-bild--bildM-2 {
    grid-column-start: 18;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-LS, .projekt__entstehung-bild--bildL-LS {
    grid-column-start: 1;
    grid-column-end: 21;
  }
  .projekt__bau-bild--bildS-LS, .projekt__entstehung-bild--bildS-LS {
    grid-column-start: 23;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-SL, .projekt__entstehung-bild--bildL-SL {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildS-SL, .projekt__entstehung-bild--bildS-SL {
    grid-column-start: 1;
    grid-column-end: 9;
  }
  .projekt__konzept-wrap {
    padding-left: calc(21.68vw - 10px);
    padding-right: calc(10.84vw - 10px);
  }
}
@media (min-width: 1440px) and (max-width: 2561px) {
  .projekt__projekte {
    grid-template-columns: repeat(24, 1fr);
  }
  .projekt__beschriftung {
    position: sticky;
    top: 62px;
    left: 16px;
    width: calc((100% - 0px - 208px) / 12 * 2 + 16px + 0px);
  }
  .projekt__projekt {
    grid-column-start: 1;
    grid-column-end: -1;
    display: grid;
  }
  .projekt__projekt--bau, .projekt__projekt--entstehung {
    padding-top: 62px;
    grid-column-start: 5;
    grid-column-end: -4;
    grid-template-columns: repeat(17, 1fr);
  }
  .projekt__projekt--konzept {
    grid-template-columns: 1fr;
  }
  .projekt__projekt-container {
    grid-template-columns: repeat(24, 1fr);
  }
  .projekt__projekt-container--entstehung {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__menue {
    top: 18px;
  }
  .projekt__bau-video-container, .projekt__entstehung-video-container {
    margin-bottom: 158px;
  }
  .projekt__bau-bild, .projekt__entstehung-bild {
    padding-bottom: 158px;
  }
  .projekt__bau-bild--hauptbild-bildXlQuer, .projekt__entstehung-bild--hauptbild-bildXlQuer {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__bau-bild--hauptbild-bildXlHoch, .projekt__entstehung-bild--hauptbild-bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .projekt__bau-bild--bildXlHoch, .projekt__entstehung-bild--bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .projekt__bau-bild--bildXlHoch-0, .projekt__entstehung-bild--bildXlHoch-0 {
    grid-column-start: 6;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildQuer, .projekt__entstehung-bild--bildQuer {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .projekt__bau-bild--bildQuer-0, .projekt__entstehung-bild--bildQuer-0 {
    grid-column-start: 6;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildM-1, .projekt__entstehung-bild--bildM-1 {
    grid-column-start: 1;
    grid-column-end: 8;
  }
  .projekt__bau-bild--bildM-2, .projekt__entstehung-bild--bildM-2 {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-LS, .projekt__entstehung-bild--bildL-LS {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .projekt__bau-bild--bildS-LS, .projekt__entstehung-bild--bildS-LS {
    grid-column-start: 15;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-SL, .projekt__entstehung-bild--bildL-SL {
    grid-column-start: 6;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildS-SL, .projekt__entstehung-bild--bildS-SL {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .projekt__konzept-wrap {
    padding-left: calc(16.388vw - 8px);
    padding-right: calc(11.7355vw - 4px);
  }
}
@media (min-width: 2562px) {
  .projekt__projekte {
    grid-template-columns: repeat(36, 1fr);
  }
  .projekt__beschriftung {
    position: sticky;
    top: 62px;
    left: 16px;
    width: calc((100% - 0px - 234px) / 12 * 2 + 18px + 0px);
  }
  .projekt__projekt {
    grid-column-start: 1;
    grid-column-end: -1;
    display: grid;
  }
  .projekt__projekt--bau, .projekt__projekt--entstehung {
    padding-top: 62px;
    grid-column-start: 5;
    grid-column-end: -4;
    grid-template-columns: repeat(17, 1fr);
  }
  .projekt__projekt--konzept {
    grid-template-columns: 1fr;
  }
  .projekt__projekt-container {
    grid-template-columns: repeat(36, 1fr);
  }
  .projekt__projekt-container--entstehung {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__menue {
    top: 18px;
  }
  .projekt__bau-video-container, .projekt__entstehung-video-container {
    margin-bottom: 158px;
  }
  .projekt__bau-bild, .projekt__entstehung-bild {
    padding-bottom: 158px;
  }
  .projekt__bau-bild--hauptbild-bildXlQuer, .projekt__entstehung-bild--hauptbild-bildXlQuer {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .projekt__bau-bild--hauptbild-bildXlHoch, .projekt__entstehung-bild--hauptbild-bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .projekt__bau-bild--bildXlHoch, .projekt__entstehung-bild--bildXlHoch {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .projekt__bau-bild--bildXlHoch-0, .projekt__entstehung-bild--bildXlHoch-0 {
    grid-column-start: 6;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildQuer, .projekt__entstehung-bild--bildQuer {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .projekt__bau-bild--bildQuer-0, .projekt__entstehung-bild--bildQuer-0 {
    grid-column-start: 6;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildM-1, .projekt__entstehung-bild--bildM-1 {
    grid-column-start: 1;
    grid-column-end: 8;
  }
  .projekt__bau-bild--bildM-2, .projekt__entstehung-bild--bildM-2 {
    grid-column-start: 11;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-LS, .projekt__entstehung-bild--bildL-LS {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .projekt__bau-bild--bildS-LS, .projekt__entstehung-bild--bildS-LS {
    grid-column-start: 15;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildL-SL, .projekt__entstehung-bild--bildL-SL {
    grid-column-start: 6;
    grid-column-end: -1;
  }
  .projekt__bau-bild--bildS-SL, .projekt__entstehung-bild--bildS-SL {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .projekt__konzept-wrap {
    padding-left: calc(11.008vw - 8px);
    padding-right: calc(7.9435vw - 4px);
  }
}
.body--startseite .main {
  margin-top: 82px;
  min-height: 100vh;
  grid-auto-rows: max-content;
}

.projekte__grain-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  mix-blend-mode: overlay;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
}
.projekte__titel-container {
  direction: ltr;
  overflow: hidden;
  position: fixed;
  top: 21.5%;
  right: -22px;
  width: 100%;
  height: 110px;
  z-index: 1;
}
.projekte__projekt {
  grid-column: span 3;
  margin-bottom: 27.5%;
  text-decoration: none;
  width: 50%;
  position: relative;
}
.projekte__projekt:first-child {
  padding-top: 0;
}
.projekte__projekt .projekte__grain {
  opacity: 0;
  mix-blend-mode: hard-light;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/bausteine/allgemein/allgemein__grain.png");
  pointer-events: none;
}
.projekte__projekt[data-hover=true] .projekte__bild-bild {
  transform: scale(2.35);
  transform-origin: left top;
  z-index: 10;
  filter: none;
}
.projekte__projekt[data-grain=true] .projekte__bild-bild {
  transform: scale(1);
  transform-origin: left top;
  transition: filter 0.3s ease;
}
.projekte__projekt[data-grain=true] .projekte__grain {
  opacity: 1;
}
.projekte__projekt[data-grain=true] .projekte__beschriftung {
  filter: blur(2px);
  opacity: 0.7;
}
.projekte__bild {
  width: 100%;
  filter: none;
  position: relative;
}
.projekte__bild-bild {
  pointer-events: none;
}
.projekte__beschriftung {
  width: 100%;
  text-decoration: none;
}
.projekte__beschriftung--desktop {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.projekte__beschriftung--smartphone {
  display: none;
}
.projekte__beschreibung {
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  margin: 0 -5px 20px -5px;
  width: 100%;
  text-decoration: none;
}
.projekte__text {
  margin: 0 5px 0 5px;
  text-decoration: none;
  color: #000;
}
.projekte__titel {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  padding-bottom: 12px;
  text-decoration: none;
  color: #000;
  width: 100%;
}

@media (max-width: 767px) {
  .body--startseite .main {
    direction: rtl;
  }
  .projekte {
    margin-top: 147px;
  }
  .projekte__projekt {
    direction: ltr;
    filter: blur(2px);
    width: 100%;
  }
  .projekte__projekt:first-child {
    padding-top: 0;
  }
  .projekte__projekt:hover {
    z-index: 0;
  }
  .projekte__projekt:hover .projekte__bild {
    transform: scale(100%);
    transform-origin: left top;
  }
  .projekte__beschriftung {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .projekte__beschriftung--desktop {
    display: none;
  }
  .projekte__beschriftung--smartphone {
    display: block;
  }
  .projekte__titel--desktop {
    display: none;
  }
  .projekte__bild {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .projekte.main {
    padding-bottom: 40vw;
  }
  .projekte__projekt {
    display: flex;
    flex-direction: column;
  }
  .projekte__beschreibung {
    display: flex;
    flex-wrap: wrap;
  }
  .projekte__titel--desktop {
    display: block;
  }
  .projekte__titel-container--smartphone {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .projekte.main {
    padding-bottom: 30vw;
  }
  .projekte__projekt {
    display: flex;
    flex-direction: column;
  }
  .projekte__beschreibung {
    display: flex;
    flex-wrap: wrap;
  }
  .projekte__titel--desktop {
    display: block;
  }
  .projekte__titel-container--smartphone {
    display: none;
  }
}
@media (min-width: 1440px) and (max-width: 2561px) {
  .projekte.main {
    padding-bottom: 22vw;
  }
  .projekte__projekt {
    display: flex;
    flex-direction: column;
  }
  .projekte__beschreibung {
    display: flex;
    flex-wrap: wrap;
  }
  .projekte__titel--desktop {
    display: block;
  }
  .projekte__titel-container--smartphone {
    display: none;
  }
}
@media (min-width: 2562px) {
  .projekte__projekt {
    display: flex;
    flex-direction: column;
  }
  .projekte__beschreibung {
    display: flex;
    flex-wrap: wrap;
  }
  .projekte__titel--desktop {
    display: block;
  }
  .projekte__titel-container--smartphone {
    display: none;
  }
}
.body--werkliste .main {
  padding-top: 75px;
}

.werkliste {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
}
.werkliste__projekt {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 100px;
}
.werkliste__projekt:last-child {
  margin-bottom: 0;
}
.werkliste__titel {
  font-size: 15px;
  line-height: 18px;
}
.werkliste__jahr {
  font-size: 15px;
  line-height: 20px;
}
.werkliste__nummer {
  font-size: 15px;
  line-height: 18px;
}
.werkliste__titel-container, .werkliste__ort, .werkliste__nummer {
  margin-bottom: 20px;
}
.werkliste__zum-projekt {
  font-size: 10px;
  line-height: 18px;
  color: #000;
  position: relative;
  top: -3px;
}
.werkliste__bild {
  display: none;
}

@media (max-width: 767px) {
  .body--werkliste .main {
    padding-top: 240px;
  }
  .werkliste {
    display: flex;
  }
  .werkliste__projekt {
    grid-column: 1/5;
  }
  .werkliste__jahr {
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 33px) / 4 * 1 + 0px + 0px);
  }
  .werkliste__nummer {
    margin-bottom: 5px;
    margin-left: calc((100% - 0px - 33px) / 4 * 1 + 11px + 0px);
    width: calc((100% - 0px - 33px) / 4 * 1 + 0px + 0px);
    margin-right: calc((100% - 0px - 33px) / 4 * 0 + 11px + 0px);
  }
  .werkliste__ort {
    width: calc((100% - 0px - 33px) / 4 * 2 + 11px + 0px);
  }
  .werkliste__titel-container {
    margin-left: calc((100% - 0px - 33px) / 4 * 1 + 11px + 0px);
    width: calc((100% - 0px - 33px) / 4 * 4 + 33px + 0px);
    display: flex;
    flex-wrap: wrap;
  }
  .werkliste__titel {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .werkliste {
    display: flex;
  }
  .werkliste__projekt {
    grid-column: 1/6;
  }
  .werkliste__jahr {
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 64px) / 5 * 1 + 0px + 0px);
  }
  .werkliste__nummer {
    margin-bottom: 5px;
    margin-left: calc((100% - 0px - 64px) / 5 * 1 + 16px + 0px);
    width: calc((100% - 0px - 64px) / 5 * 1 + 0px + 0px);
    margin-right: calc((100% - 0px - 64px) / 5 * 0 + 16px + 0px);
  }
  .werkliste__ort {
    width: calc((100% - 0px - 64px) / 5 * 3 + 32px + 0px);
  }
  .werkliste__titel-container {
    margin-left: calc((100% - 0px - 64px) / 5 * 1 + 16px + 0px);
    width: calc((100% - 0px - 64px) / 5 * 4 + 48px + 0px);
    display: flex;
    flex-wrap: wrap;
  }
  .werkliste__titel {
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .werkliste {
    display: flex;
  }
  .werkliste__projekt {
    grid-column: 1/9;
  }
  .werkliste__jahr {
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 112px) / 8 * 1 + 0px + 0px);
  }
  .werkliste__nummer {
    margin-left: calc((100% - 0px - 112px) / 8 * 1 + 16px + 0px);
  }
  .werkliste__nummer, .werkliste__ort {
    width: calc((100% - 0px - 112px) / 8 * 1 + 0px + 0px);
    margin-right: calc((100% - 0px - 112px) / 8 * 0 + 16px + 0px);
  }
  .werkliste__titel-container {
    display: flex;
    flex-wrap: wrap;
    width: calc((100% - 0px - 112px) / 8 * 5 + 64px + 0px);
  }
  .werkliste__titel {
    width: 100%;
  }
}
@media (min-width: 1440px) and (max-width: 2561px) {
  .werkliste {
    display: flex;
  }
  .werkliste__projekt {
    grid-column: 1/12;
  }
  .werkliste__jahr {
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 160px) / 11 * 1 + 0px + 0px);
  }
  .werkliste__nummer {
    margin-left: calc((100% - 0px - 160px) / 11 * 1 + 16px + 0px);
  }
  .werkliste__nummer, .werkliste__ort {
    width: calc((100% - 0px - 160px) / 11 * 1 + 0px + 0px);
    margin-right: calc((100% - 0px - 160px) / 11 * 0 + 16px + 0px);
  }
  .werkliste__titel-container {
    margin-bottom: 15px;
    display: flex;
    width: calc((100% - 0px - 160px) / 11 * 8 + 112px + 0px);
  }
  .werkliste__zum-projekt-container {
    margin-left: 7px;
    position: relative;
  }
  .werkliste__zum-projekt:hover + .werkliste__bild {
    display: block;
  }
  .werkliste__bild {
    position: absolute;
    top: 20px;
    left: 0;
    width: 11.806vw;
    display: none;
    z-index: 10;
  }
}
@media (min-width: 2562px) {
  .werkliste {
    display: flex;
  }
  .werkliste__projekt {
    grid-column: 1/18;
  }
  .werkliste__jahr {
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 288px) / 17 * 1 + 0px + 0px);
  }
  .werkliste__nummer {
    margin-left: calc((100% - 0px - 288px) / 17 * 1 + 18px + 0px);
    padding-bottom: 17px;
  }
  .werkliste__nummer, .werkliste__ort {
    width: calc((100% - 0px - 288px) / 17 * 1 + 0px + 0px);
    margin-right: calc((100% - 0px - 288px) / 17 * 0 + 18px + 0px);
  }
  .werkliste__titel-container {
    margin-bottom: 15px;
    display: flex;
    width: calc((100% - 0px - 288px) / 17 * 14 + 234px + 0px);
  }
  .werkliste__zum-projekt-container {
    margin-left: 7px;
    position: relative;
  }
  .werkliste__zum-projekt:hover + .werkliste__bild {
    display: block;
  }
  .werkliste__bild {
    position: absolute;
    top: 20px;
    left: 0;
    width: 6.641vw;
    display: none;
    z-index: 10;
  }
}
.macro-bild {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.macro-bild .lazyload,
.macro-bild .lazyloading {
  opacity: 0;
}
.macro-bild .lazyloaded {
  transition: background-color 500ms ease-in;
  opacity: 1;
  background-color: transparent;
  /*  transition is applied to lazyloaded not lazyload */
  transition: opacity 500ms ease-in;
}

.macro-bild__img,
.macro-bild__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.macro-video {
  position: relative;
  overflow: hidden;
}
.macro-video__iframe, .macro-video iframe {
  background-color: transparent;
  border: none;
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.main {
  position: relative;
  font-family: "MessinaSans", sans-serif;
  display: grid;
  column-gap: 16px;
  padding: 0 20px 0 20px;
}

@media (max-width: 767px) {
  .main {
    column-gap: 11px;
    padding-top: 240px;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .main {
    padding-top: 0px;
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .main {
    padding-top: 0px;
    grid-template-columns: repeat(9, 1fr);
  }
}
@media (min-width: 1440px) and (max-width: 2561px) {
  .main {
    padding-top: 82px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 2562px) {
  .main {
    padding-top: 0px;
    grid-template-columns: repeat(18, 1fr);
  }
}